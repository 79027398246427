import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Loader from './widgets/Loader';
import Layout from './widgets/Layout'; 

const Navigation        = lazy(() => import('./screens/Navigation'));
const PerformanceList   = lazy(() => import('./screens/performance/List'));
const PerformanceDetail = lazy(() => import('./screens/performance/Detail'));
const EventList         = lazy(() => import('./screens/event/List'));
const EventDetail       = lazy(() => import('./screens/event/Detail'));
const EventCashier      = lazy(() => import('./screens/event/Cashier'));
const EventOrder        = lazy(() => import('./screens/event/Order'));

export function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path='/' element={<Layout />}>
            <Route exact path='/navigation' element={<Navigation />} />
            
            <Route exact path='/' element={<PerformanceList />} />
            
            <Route exact path='/performances' element={<PerformanceList />} />
            <Route exact path='/performance/:name' element={<PerformanceDetail />} />
            
            <Route exact path='/events' element={<EventList />} />
            <Route exact path='/event/:id' element={<EventDetail />} />
            <Route exact path='/event/cashier/:id' element={<EventCashier />} />
            <Route exact path='/event/order/:id' element={<EventOrder />} />
          </Route>
          
        </Routes>
      </Suspense>
    </Router>
  )
}
