import { Link, Outlet } from 'react-router-dom';

export default function Layout() {
  return (
    <>
    <header className='header'>
      <Link to='/'>
        <img src='https://www.bebook.am/__resource/img/logo.png' className='logo' alt='Logo'/>
      </Link>
      
      <Link to='/navigation'>
        <img src='https://www.bebook.am/__resource/img/icon/menu.png' className='menu' alt='Navigation'/>
      </Link>
    </header>
    
    <Outlet />
    
    </>
  )
}
